var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pg_setting_container"},[_vm._m(0),_c('div',{staticClass:"pg_setting_controls_container a_padding-top-20"},[_c('div',{staticClass:"pg_branches"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.gymId),expression:"gymId"}],staticClass:"v_transparent",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.gymId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":undefined}},[_vm._v("Selecteer een vestiging...")]),_vm._l((_vm.gyms),function(vestigingOption){return _c('option',{key:vestigingOption.id,domProps:{"value":vestigingOption.id}},[_vm._v(" "+_vm._s(vestigingOption.naam)+" ")])})],2)])]),_c('VueScrollTable',{staticClass:"pg_coach-logins_table",class:{ 's_no-data': _vm.tableData.length === 0 },attrs:{"data":_vm.tableData,"headers":_vm.table.headers,"classes":_vm.table.stylingClasses,"limits":[10, 20, 30],"translations":{ limit: 'per pagina' }},scopedSlots:_vm._u([{key:"heeft login?",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"flex-1 text-left"},[_vm._v(" "+_vm._s(data)+" ")])])]}},{key:"acties",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"flex items-center"},[_c('button',{staticClass:"button v_transparent v_is-icon v_icon-size-16 v_smallest a_fill_brand_secondary pg_button m-0",on:{"click":function($event){return _vm.$store.dispatch('modal/openModal', {
							name: 'coach-login',
							data: { gymId: _vm.gymId, coach: data.attributes.coach },
						})}}},[_c('icon',{attrs:{"icon-id":"icon_edit2"}})],1),_c('button',{staticClass:"button v_transparent v_is-icon v_icon-size-16 v_smallest a_fill_brand_secondary pg_button m-0",attrs:{"disabled":!data.attributes.coach.hasLogin},on:{"click":function($event){_vm.$store.dispatch('modal/openModal', {
							name: 'assign-members',
							data: {
								coach: data.attributes.coach,
								gymId: _vm.gymId,
								callback: function () { return _vm.queryClient.refetchQueries({ queryKey: _vm.queryKey }); },
							},
						})}}},[_c('icon',{attrs:{"icon-id":"icon_cross"}})],1)])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"pg_header"},[_c('div',{staticClass:"pg_header_heading"},[_c('h2',[_vm._v("Beheer")])]),_c('div',{staticClass:"pg_header_description"},[_vm._v(" Hier kun je per vestiging de inloggegevens van bestaande coaches wijzigen of verwijderen. ")])])}]

export { render, staticRenderFns }