













































































import { computed, defineComponent, watch } from "@vue/composition-api";
import { useQuery, useQueryClient } from "@tanstack/vue-query";
import { StorageSerializers, useStorage } from "@vueuse/core";
// @ts-expect-error Not typed
import VueScrollTable from "vue-scroll-table";
import { coreApi } from "@/lib/backend";
import { getFullName } from "@/lib/getFullName";
import { useGyms } from "@/lib/query/hooks/useGyms";
import { stylingClasses } from "@/lib/vue-scroll-table-settings.js";

export default defineComponent({
	name: "SettingsCoaches",
	components: {
		VueScrollTable,
	},
	setup() {
		const queryClient = useQueryClient();
		const { data: gyms } = useGyms();

		const gymId = useStorage<number>("selectedVestigingId", gyms.value[0]?.id, localStorage, {
			serializer: StorageSerializers.number,
		});

		watch(
			[gyms, gymId],
			([currentGyms, currentGymId]) => {
				if (currentGyms.length === 0 || currentGyms.find((gym) => gym.id === currentGymId)) {
					return;
				}

				gymId.value = currentGyms[0].id;
			},
			{ immediate: true },
		);

		const queryKey = computed(() => ["coaches", gymId.value] as const);
		const enabled = computed(() => !!gyms.value.find((gym) => gym.id === gymId.value));

		const { data: coaches } = useQuery({
			queryKey,
			queryFn: async (context) =>
				await coreApi.coaches
					.coachControllerFindAll({ gymId: context.queryKey[1] }, { signal: context.signal })
					.then((response) => response.data.data),
			select: (data) => data.sort((a, b) => getFullName(a).localeCompare(getFullName(b))),
			initialData: [] as Awaited<
				ReturnType<typeof coreApi.coaches.coachControllerFindAll>
			>["data"]["data"],
			enabled,
		});

		const tableData = computed(() =>
			coaches.value.map(
				(coach) =>
					[getFullName(coach), coach.hasLogin ? "Ja" : "Nee", { attributes: { coach } }] as const,
			),
		);

		return {
			queryKey,
			queryClient,
			table: {
				headers: [
					{ text: "Naam" },
					{ text: "Heeft login?", width: 100 },
					{ text: "Acties", width: 110 },
				],
				stylingClasses,
			},
			tableData,
			gyms,
			gymId,
			coaches,
		};
	},
});
